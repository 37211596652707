<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-row>
      <v-col>
        <v-sheet class="pa-8 mb-4">
          <h1 class="mb-2">{{ $t("documents-heading-1") }}</h1>
          <v-list>
            <v-list-item
              v-for="download in association"
              :key="download.filename"
              @click="openPdf(download.url)"
            >
              <v-list-item-icon>
                <v-icon v-if="download.icon" :color="download.color">{{
                  download.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="download.filename"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-8 mb-4">
          <h1 class="mb-2">{{ $t("documents-heading-2") }}</h1>
          <v-list>
            <v-list-item
              v-for="download in group"
              :key="download.filename"
              @click="openPdf(download.url)"
            >
              <v-list-item-icon>
                <v-icon v-if="download.icon" :color="download.color">{{
                  download.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="download.filename"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "documents",
  components: {
    Breadcrumbs
  },
  data: () => ({
    association: [
      {
        filename: "Satzung.pdf",
        url: "../Satzung.pdf",
        icon: "mdi-file-document-outline",
        color: "red"
      },
      {
        filename: "Mitgliedsantrag.pdf",
        url: "../Mitgliedsantrag.pdf",
        icon: "mdi-file-document-outline",
        color: "red"
      },
      {
        filename: "SEPA-Lastschriftmandat.pdf",
        url: "../SEPA-Lastschriftmandat.pdf",
        icon: "mdi-file-document-outline",
        color: "red"
      },
      {
        filename: "Datenschutzordnung",
        url: "../Datenschutzordnung.pdf",
        icon: "mdi-file-document-outline",
        color: "red"
      },
      {
        filename: "Datenschutzrechtliche Informationen und Einwilligung",
        url: "../Datenschutzrechtliche_Informationen_und_Einwilligung.pdf",
        icon: "mdi-file-document-outline",
        color: "red"
      }
    ],
    group: [
      {
        filename: "Selbsthilfewegweiser Bauchgefühle 2020.pdf",
        url: "../Selbsthilfewegweiser-Bauchgefuehle-2020.pdf",
        icon: "mdi-file-document-outline",
        color: "red"
      },
      {
        filename: "Gruppenregeln.pdf",
        url: "../Gruppenregeln.pdf",
        icon: "mdi-file-document-outline",
        color: "red"
      },
      {
        filename: "Anleitung virtueller Gruppenraum.pdf",
        url: "../Anleitung_virtueller_Gruppenraum.pdf",
        icon: "mdi-file-document-outline",
        color: "red"
      },
      {
        filename: "Bauchgefühle Flyer.pdf",
        url: "../Bauchgefuehle_Flyer.pdf",
        icon: "mdi-file-document-outline",
        color: "red"
      },
      {
        filename: "Bauchgefühle Poster.pdf",
        url: "../Bauchgefuehle-Poster.pdf",
        icon: "mdi-file-document-outline",
        color: "red"
      }
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("documents"),
          disabled: true
        }
      ];
    }
  },
  methods: {
    openPdf: function(url) {
      let route = this.$router.resolve(url);
      window.open(route.href, "_blank");
    }
  }
};
</script>
